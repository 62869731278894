.thin-background-frame {
    width: 100%;
    max-width: 1920px;
    margin-top: 20vh;
    height: 10vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    background-color: cadetblue;
}

.thin-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.thin-title {
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.thin-subtitle {
    font-size: 1.4rem;
    color: white;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.empty-space {
    background-color: aliceblue;
    height: 10vh;
}

/* MOBILE */

@media only screen and (max-device-width: 600px){

    .thin-background-frame {
        max-width: 100%; /* Ensure it doesn't exceed the viewport width */
        width: 100%;
        height: 10vh;
        padding: 20px;
    }

    .thin-text-container {
        width: 100%; /* Take full width on mobile */
        padding: 0 20px; /* Add some horizontal padding */
    }
  
  }