.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.section_hero {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1920px;
  width: 100%;
  height: 60vh;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fmother_full.jpg?alt=media&token=32393d53-e796-439c-83e4-43ddaa4b0fd4');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
}
.heroContainer {
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heroText {
  margin: 0 0 10px;
  margin-top: 15%;
  padding: 0;
  font-size: 2.5em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0 2px 0 rgba(31, 31, 31, 0.4);
}
.heroSubText {
  margin: 0 0 10px;
  padding: 0;
  font-size: 1.2em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0 2px 0 rgba(60, 60, 60, 0.6);
}
.HeroImgContainer{
  display: flex;           
  flex: 1;
  justify-content: center; 
  align-items: center; 
}
.HeroImgContainer img{
  max-width: 95%;
}

/* Warrior Section */
.section_warrior {
  max-width: 1920px;
  width: 100%;
  height: 600px;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border: 0px solid yellow;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fwarrior_full.JPG?alt=media&token=c8075b53-6ef5-4c7a-8dd3-3eb8b6766cc4');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

/* Teacher Section */
.section_teacher {
  max-width: 1920px;
  width: 100%;
  height: 600px;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border: 0px solid yellow;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fteacher_full.jpg?alt=media&token=8a5b6ebf-2eed-452d-8b69-f869d5f89f54');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

/* Mother Section */
.section_mother {
  max-width: 1920px;
  width: 100%;
  height: 600px;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px solid yellow;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_mother_full.JPG?alt=media&token=da4967b0-310b-4a49-94ff-f037adf632d8');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Black Text */
.blackText {
  margin: 0 0 10px;
  margin-top: 15%;
  padding: 0;
  font-size: 2.5em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 400;
  font-style: italic;
  color: #000000;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0 2px 0 rgba(255, 255, 255, 0.4);
}
.blackSubText {
  margin: 0 0 10px;
  padding: 0;
  font-size: 1.1em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 200;
  font-style: normal;
  color: #2c2c2c;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* White Text */
.whiteText {
  margin: 0 0 10px;
  margin-top: 15%;
  padding: 0;
  font-size: 2.5em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0 2px 0 rgba(31, 31, 31, 0.4);
}
.whiteSubText {
  margin: 0 0 10px;
  padding: 0;
  font-size: 1.1em;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  font-weight: 200;
  font-style: normal;
  color: #e5e5e5;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexChild {
  align-items: flex-start;
  display: flex;
}

.section_child {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Navbar */
.navbar {
  background-color: "";
  overflow: hidden;
  font-family: Neucha, sans-serif;
  font-family: 'Caveat', cursive;
  padding: 20px;
}

.navbar a {
  float: left; /* This will make the logo and the brand name float to the left */
  color: #6D829B;
  text-decoration: none;
  padding: 0 16px;  /* Adjusted padding to only have it on the sides */
}

.navbar-menu {
  float: right;
}

.navbar-menu li {
  display: inline;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  float: left;
  display: block;
}
.navbar-brand img {
  height: 40%;
  width: 40%;        
}
.navbar .darkBlueBtn {
  float: right;
}

 /* Mobile Navbar */
.mobile-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*background-color: #91ADCE;*/
  padding: 10px 20px;
}

.navbar-logo {
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
}

.menu-toggle {
  display: block;
  position: relative;
  z-index: 1;
  user-select: none;
  cursor: pointer;
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  margin: 3px 0;
  transition: 0.3s;
}

.navbar-menu {
  display: none;
  list-style-type: none;
  padding: 0;
}

.navbar-menu li {
  padding: 10px;
}

.navbar-menu li a {
  color: #ce4c4c;
  text-decoration: none;
}

/* Show menu when the menu icon is clicked */
.show .navbar-menu {
  display: block;
}

/* Location Section */
.section_location { 
  max-width: 1920px;
  width: 100%;
  height: 600px;
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Flocation.jpg?alt=media&token=1496490c-4be2-49c7-9239-98503005d41a');
}

.parking {
  position: relative;
  text-align: center;

  margin-bottom: 50px;
}

.parking h1 {

  font-size: 2rem;
  color: black;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  font-family: Neucha, sans-serif;
  text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.center-button {
  position: absolute;
  margin-top: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  max-width: 500px;
}

.parkingMap {
  font-family: Neucha, sans-serif;
}

.centered-section {
  width: 100%;
  height: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.centered-title {
  font-size: 2rem;
  color: black;
  text-align: center;
  position: relative;
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: 'Neucha', sans-serif;
  font-family: 'Caveat', cursive;
}

.centered-subtitle {
  font-size: 1rem;
  color: black;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  font-family: 'Neucha', sans-serif;
  font-family: 'Caveat', cursive;
}

.centered-title::after {
  content: attr(data-text); /* Duplicate the text content */
  position: absolute;
  top: 100%; /* Position it right below the original text */
  left: 0;
  width: 100%;
  text-align: center;
  transform: scaleY(-1); /* Flip the text vertically */
  opacity: 0.5; /* Make it semi-transparent */
  filter: blur(2px); /* Apply blur */
  color: rgba(0, 0, 0, 0.4); /* Slightly faded color for reflection */
}

/* Rainbow colors */
.rainbow-0 { color: red; }
.rainbow-1 { color: orange; }
.rainbow-2 { color: yellow; }
.rainbow-3 { color: green; }
.rainbow-4 { color: blue; }
.rainbow-5 { color: indigo; }
.rainbow-6 { color: violet; }



/* MOBILE */

@media only screen and (max-device-width: 600px){
        
  .section_hero {
      max-width: 1920px;
      width: 100%;
      height: 350px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .section_warrior {
      max-width: 1920px;
      width: 100%;
      height: 350px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  }
  .section_teacher {
    max-width: 1920px;
    width: 100%;
    height: 350px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .section_mother {
    max-width: 1920px;
    width: 100%;
    height: 350px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  /* White Text */
  .whiteText {
    margin: 0 0 10px;
    margin-top: 15%;
    padding: 0;
    font-size: 2em;
    font-weight: 400;
    font-style: italic;
    color: #ffffff;
    flex: 1;
    flex-direction: column;
  }
  .whiteSubText {
    margin: 0 0 10px;
    padding: 0;
    font-size: 0.3em;
    font-weight: 200;
    font-style: normal;
    color: #e5e5e5;
    flex: 1;
  }

  /* Black Text */
  .blackText {
    margin: 0 0 10px;
    margin-top: 15%;
    padding: 0;
    font-size: 2em;
    font-weight: 400;
    font-style: italic;
    color: #101010; 
    flex: 1;
  }
  .blackSubText {
    margin: 0 0 10px;
    padding: 0;
    font-size: 0.3em;
    font-weight: 200;
    font-style: normal;
    color: #707070;
    flex: 1;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
