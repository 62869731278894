.background-frame {
    width: 100%;
    max-width: 1920px;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 50px;
    box-sizing: border-box;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.title {
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.subtitle {
    font-size: 1.4rem;
    color: white;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

/* Right Side */
.background-frame-right {
    max-width: 1920px;
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 50px;
    box-sizing: border-box;
}

.text-container-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.title-right {
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.subtitle-right {
    font-size: 1.4rem;
    color: white;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.title, .subtitle, .title-right, .subtitle-right {
    padding: 10px 20px; /* Add some padding around the text */
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
    backdrop-filter: blur(20px); /* Blur effect */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text color */
    margin: 5px 0; /* Some margin to separate title and subtitle */
}   

/* MOBILE */

@media only screen and (max-device-width: 600px){

    .background-frame, .background-frame-right {
        max-width: 100%; /* Ensure it doesn't exceed the viewport width */
        width: 100%;
        height: 80vh;
        padding: 20px;
    }

    .text-container, .text-container-right {
        width: 100%; /* Take full width on mobile */
        padding: 0 20px; /* Add some horizontal padding */
    }
  
  }