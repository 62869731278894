.container {
    position: fixed;
    top: 0;
    right: 0;
}

.corner-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.7rem;
    color: white;
    font-family: "chelsea-market-pro", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid black;
}

.dialog {
    position: fixed;
    
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    z-index: 999;
}
