.rsvpPage {
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.container {
    width: 350px;
    height: 250px;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.5rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.form-container form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Ensures that form takes the full width of .form-container */

    
}

.form-container form div {
    margin-bottom: 10px;
}

.form-container form div label {
    display: block;
    margin-bottom: 5px;
}

.form-container form div input[type="text"],
.form-container form div input[type="email"],
.form-container form div textarea {
    width: 100%;
    min-width: 300px;
    box-sizing: border-box; /* This ensures that padding and border are included in the element's total width and height */
    padding: 10px; /* Adds some padding inside the input */
    border: 1px solid #ccc; /* Adds a border around the input */
    border-radius: 4px; /* Optional: Adds rounded corners to the input */
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.0rem;
}


.form-container form div input[type="radio"] {
    margin-right: 10px;
}
