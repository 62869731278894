.google-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Take full viewport height */
}

.google-map-frame {
    width: 80%;
    height: 250px;
    border: 0;
    border-radius: 10px;
    margin-bottom: 20px; /* Space between iframe and title */
}

.map-title {
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.5rem;
    margin-bottom: 10px; /* Space between title and subtitle */
    color: white;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.map-subtitle {
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.2rem;
    color: white;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
}

.map-title, .map-subtitle {
    padding: 10px 20px; /* Add some padding around the text */
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black background */
    backdrop-filter: blur(2px); /* Blur effect */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text color */
    margin: 5px 0; /* Some margin to separate title and subtitle */
}   

/* MOBILE */
@media only screen and (max-device-width: 600px){

}
