
.signature-popup-overlay {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: scroll;
}

.signature-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1001;
    
}
