.slideshow-container {
    width: 100%;
    height: 80vh;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.slide-title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 1.5rem;
    border-radius: 5px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
}

@media only screen and (max-device-width: 600px){
    .slideshow-container {
        height: 40vh;
    }
}