.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.card-background {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0ABF04;
    display: flex;
    align-items: center;
    justify-content: center;
}


form {
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
}

form input, form textarea {
    margin-bottom: 10px;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.0rem;
}

.card-form-container {

    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.5rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.card-form-container form div input[type="text"],
.card-form-container form div textarea {
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1.0rem;
}

.card-bottom-title {
    font-size: 2rem;
    font-family: 'Chelsea Market', cursive;
    font-weight: 400;
    font-style: normal;
    color: green;
    text-shadow: 0 1px 0 rgba(31, 31, 31, 0.4);
    width: 70%;
}

.card-bottom-subtitle {
    font-size:0.7rem;
    font-family: 'Chelsea Market', cursive;
    font-weight: 100;
    font-style: italic;
    color: rgb(143, 159, 143);
    margin-bottom: 50px;
    width: 70%;
}

.signatures-container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* This will create columns that are at least 300px wide */
    gap: 20px; /* spacing between grid items */
    justify-items: center; /* horizontally center the items */
}

.signature-item {
    box-sizing: border-box;
    padding: 10px;
}

.signature-note {
    text-align: start;
}

.signature-details {
    font-size: 2rem;
    font-family: "Just Another Hand", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgb(89, 128, 212);
    margin: 25px;
    width:80%;
    font-style: italic;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.4);

    opacity: 0;
    transition: opacity 1s ease-in; 
}

.signature-details.fade-in {
    opacity: 1;
    /*pointer-events: auto;
    z-index: 1000;*/
}

.card-text-container {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    max-width: 30%;
}

.card-title {
    
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-family: 'Chelsea Market', cursive;
    font-weight: 400;
    font-style: normal;
}

.card-subtitle {
    font-size: 1.4rem;
    color: white;
    font-family: 'Chelsea Market', cursive;
    font-weight: 400;
    font-style: normal;
}

.card-back-content{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
}


.flip-container {
    perspective: 1000px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 100%;
}

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.front {
    background: #ddd;
}

.back {
    width: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; /* Anchor content to the top */
    align-items: center; 
    overflow-y: auto;
}

.click_info {
    position: absolute; /* This allows you to position this div anywhere within the front div */
    bottom: 10px; /* Adjust as necessary to add some space from the bottom */
    right: 10px;
    font-size: 1rem;
    color: white;
    font-family: 'Merienda', cursive;
    font-weight: 200;
    font-style: normal;
}

.flip-container.flipped .flipper {
    transform: rotateY(180deg);
}


.full-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    /*z-index: 1000;*/
}

.full-message-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.flip-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 5px;
    

    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-family: 'Caveat', cursive;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .flip-button:hover {
    opacity: 1;
  }



@media only screen and (max-device-width: 768px) {

    .card-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        max-width: 60%;
    }

    .card-container {
        height: 100vh;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .card-subtitle {
        font-size: 1rem;
    }
}
